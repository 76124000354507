* {
  box-sizing: border-box;
  text-decoration: none;
  outline: none;
  font-family: 'Cairo', sans-serif;
  font-family: 'Chivo', sans-serif;
  font-family: 'Hind', sans-serif;
  font-family: 'Italianno', cursive;
  font-family: 'Lato', sans-serif;
  font-family: 'Oswald', sans-serif;
  font-family: 'Poppins', sans-serif;
  font-family: 'Readex Pro', sans-serif;
}

:root {
  --primary-color: #34D1BA !important;
  --secondray-color: #6AA9A1;
  --accordion-radius: 0;
  --text-color: #37495F;
  --secondary-text-color: #9F9F9F;
  --gray-color: #3B3C3E;
  --dark-gray-color: #4E4E4E;
  --dark-gray-color-200: #8C8C8CD9;
  --light-bg: #ECEBE7;
  --accordion-bg: #F9F9F8;
  --transition: 0.5s all ease-in-out;
}

body {
  direction: rtl;
  background-color: #ffffff;
  font-family: 'Cairo', sans-serif;
  font-family: 'Chivo', sans-serif;
  font-family: 'Hind', sans-serif;
  font-family: 'Italianno', cursive;
  font-family: 'Lato', sans-serif;
  font-family: 'Oswald', sans-serif;
  font-family: 'Poppins', sans-serif;
  font-family: 'Readex Pro', sans-serif;
  transition: var(--transition);
}


html {
  scroll-behavior: smooth;
}

/* 
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------       Gloable Classes        ----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#

*/

.container {
  margin: 0 auto;
  padding: 0 10px;
}

label {
  cursor: pointer;
}

input:focus,
button:focus {
  transition: var(--transition);
  box-shadow: 0 0 0 0.2rem #C7D2FE;
}

div:where(.swal2-container) {
  z-index: 3000 !important;
}

canvas {
  position: absolute !important;
  width: 1000px !important;
  height: 1000px !important;
  max-width: 100%;
  max-height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100000;
  pointer-events: none;
}

img {
  user-select: none;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  padding: 0 !important;
}

.p-icon {
  width: 13px !important;
  color: var(--primary-color);
}

.transition {
  transition: var(--transition) !important;
}

.num-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 111;
}

.num-container h1 {
  font-size: 15px;
  font-weight: bolder;
}

/* 
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------      End Gloable Classes     ----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#

*/

/* 
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------     Start Styling Header     ----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#

*/

header .p-dropdown .p-dropdown-label.p-placeholder,
header .p-dropdown .p-dropdown-label {
  color: black !important;
  flex: initial !important;
  width: fit-content;
  padding: 3px !important;
}

.header-routes a {
  padding: 0 10px;
  position: relative;
  width: 100%;
  display: inline-block;
  transition: var(--transition);
}

.header-routes a.active {
  color: var(--primary-color);
}

.header-routes a::after:not(.header-routes a.drop-active) {
  content: "";
  width: 0%;
  height: 3px;
  background-color: var(--primary-color);
  position: absolute;
  right: 0;
  transition: var(--transition);
  bottom: -20px;
}

.header-routes a.active::after {
  width: 100%;
}

header .p-dropdown {
  justify-content: center;
  border-color: #0d99ff38 !important;
  padding: 3px !important;
}

.home-hero {
  background-image: url(../images/squre.png);
}

.home-hero:hover {
  background-image: url(../images/squre-light.png) !important;
}


.home-pagnation>p::after {
  content: "";
  width: 100%;
  height: 3px;
  background-color: var(--primary-color);
  position: absolute;
  left: 200px;
  bottom: 0;
  transition: 1s all ease-in-out;
}

.home-pagnation>p.active::after {
  left: 0;
}

.auctions-pagnations>button:focus {
  box-shadow: none !important;
}

.auctions-pagnations>button::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: inherit;
  position: absolute;
  right: 0;
  scale: 0.5;
  top: 0;
  z-index: -1;
  transition: 1s;
}

.auctions-pagnations>button.active::after {
  scale: 1;
  background-color: var(--secondray-color);
}


/* 
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------      End Styling Header      ----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#

*/

/* 
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #---------------------------------------##############################-----------------------------------------#
    #----------------------------------------  Start Styling Prime-React   ----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#

*/

.grid-nogutter {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.p-dataview-emptymessage {
  grid-column: span 12 / span 12 !important;
}

.p-dialog-mask {
  background-color: transparent !important;
}


.p-dialog .p-dialog-content:last-of-type {
  border-radius: 10px !important;
  padding: 13px !important;
  padding-top: 0 !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 3px 10px !important;
}

.p-dropdown-panel .p-dropdown-items {
  background-color: #f5f5f5 !important;
  color: black;
}

.p-dataview .p-paginator-bottom {
  direction: ltr !important;
  border-bottom: 0 !important;
}

.p-accordion-header-link {
  justify-content: space-between;
  flex-direction: row-reverse;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 8px 20px !important;
}


.p-accordion .p-accordion-tab {
  margin-bottom: 25px !important;
  border-radius: 100px !important;
}

.p-accordion .p-accordion-content {
  background-color: #f6f6f6 !important;
}

.accordion-calc .p-accordion-content {
  background-color: transparent !important;
  border: none !important;
}

.accordion-calc .p-accordion-toggle-icon {
  position: absolute;
  width: 20px !important;
}


.product-details .p-accordion .p-accordion-header .p-accordion-header-link {
  background-color: transparent !important;
  border: none !important;
  border-top: 1px solid var(--secondray-color) !important;
  border-radius: 0 !important;
  padding: 20px 10px !important;
  padding-bottom: 10px !important;
  justify-content: center !important;
  box-shadow: none !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: var(--secondray-color) !important;
  color: white !important;
}

.product-details .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  color: var(--secondray-color) !important;
}

.product-details .p-accordion .p-accordion-tab {
  margin-bottom: 0 !important;
}

.p-dialog.p-component {
  position: relative !important;
  z-index: 99999 !important;
  margin-bottom: 100px;
  /* margin-top: 20px; */
  max-height: 80% !important;
}

.p-dialog-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.38) !important;
}

.p-dialog-mask .swiper-wrapper {
  margin-bottom: 0 !important;
}

.p-dialog-mask .swiper-button-prev,
.p-dialog-mask .swiper-rtl .swiper-button-next {
  transition: var(--transition);
}

.p-dialog-mask .swiper-button-prev,
.p-dialog-mask .swiper-rtl .swiper-button-next {
  position: fixed;
  background-color: var(--light-bg);
  width: 67px !important;
  height: 67px !important;
  border-radius: 10px;
  font-size: 10px !important;
}

.p-dialog-mask .swiper-rtl .swiper-button-next {
  left: 150px !important;
}

.p-dialog-mask .swiper-button-prev {
  right: 150px !important;
}

.p-dialog-mask .swiper-button-prev:after,
.p-dialog-mask .swiper-rtl .swiper-button-next:after {
  font-size: 20px !important;
  font-weight: bolder !important;
}

.p-dialog-mask.p-component-overlay {
  z-index: 2000 !important;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link,
.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
.p-accordion .p-accordion-header .p-accordion-header-link:not(.accordion-calc .p-accordion-header-link),
.p-accordion .p-accordion-content {
  background-color: white;
  box-shadow: none !important;
}

.admin.p-accordion .p-accordion-header .p-accordion-header-link {
  background-color: #34d1ba45 !important;
}

.accordion-calc .p-accordion-header-link {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.p-accordion .p-accordion-header.auctions-dashboard-accordion-header .p-accordion-header-link {
  border-radius: var(--accordion-radius) !important;
}

#home .p-accordion .p-accordion-header .p-accordion-header-link {
  border-radius: 5px !important;
}

.wallet.p-dialog-content:last-of-type {
  border-radius: 0 !important;
  padding: 0 !important;
}

.p-accordion-header-text {
  color: var(--secondray-color) !important;
}



/* 
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------    End Styling Prime-React   ----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#

*/

/* 
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------       End Styling Swiper     ----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#

*/


.swiper-pagination-bullet-active {
  width: 30px !important;
  border-radius: 15px !important;
  height: 8px !important;
  background-color: var(--secondray-color) !important;
  opacity: 1 !important;
}

.top-left-pagination,
.custom-pagination-wrapper {
  overflow: hidden !important;
  position: relative;
}

.top-left-pagination .swiper,
.top-left-pagination .swiper-container,
.custom-pagination-wrapper .swiper,
.custom-pagination-wrapper .swiper-container {
  overflow: initial !important;
  position: initial !important;
  padding: 30px 20px !important;
}

.custom-pagination .swiper-button-next,
.custom-pagination .swiper-button-prev {
  left: 100px !important;
  top: 65% !important;
  transform: translateY(-50%);
  background-color: white;
  color: black;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #EEEEEE;
}

.custom-pagination .swiper-button-prev {
  right: 100px !important;
}

.custom-pagination .swiper-button-next:after,
.custom-pagination .swiper-button-prev:after {
  font-size: 13px !important;
  font-weight: bolder !important;
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: -5px !important;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  color: var(--secondray-color);
}

.auction-details-page .swiper,
.swiper-container {
  position: fixed !important;
  left: 0;
  top: 0;
  height: 100vh !important;
  z-index: 1000 !important;
  width: 100% !important;
}

.slider-image-perview .swiper-button-prev:after,
.slider-image-perview .swiper-rtl .swiper-button-next:after {
  font-size: 11px !important;
  color: white;
}

.slider-image-perview .swiper-button-prev,
.slider-image-perview .swiper-rtl .swiper-button-next {
  position: absolute !important;
  border-radius: 50% !important;
  background-color: #888888 !important;
  width: 24px !important;
  height: 24px !important;
  color: white !important;
}

.slider-image-perview .swiper-rtl .swiper-button-next {
  left: 10px !important;
}

.slider-image-perview .swiper-button-prev {
  right: 10px !important;
}

.p-dialog .without-padding.p-dialog-content:last-of-type {
  padding: 0 !important;
}

/* 
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------      End Styling Swiper      ----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#

*/


/* 
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------      End Styling Animation    ----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#

*/

.animait-arrow {
  animation: animait-arrow 1s infinite alternate;
}

.hammer {
  animation: animait-hammer 2s infinite alternate;
}

@keyframes animait-arrow {

  0% {
    transform: translateY(-5px);
  }

  100% {

    transform: translateY(5px);

  }

}

@keyframes animait-hammer {

  0% {
    rotate: 45deg;
  }

  100% {

    rotate: 180deg;

  }

}

@keyframes morphing {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }

  25% {
    border-radius: 58% 42% 75% 25% / 76% 46% 54% 24%;
  }

  50% {
    border-radius: 50% 50% 33% 67% / 55% 27% 73% 45%;
  }

  75% {
    border-radius: 33% 67% 58% 42% / 63% 68% 32% 37%;
  }
}


/* 
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------      End Styling Animation      ----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#
    #----------------------------------------##############################----------------------------------------#

*/