@media (max-width: 640px) {
    .num-container h1 {
        font-size: 14px;
    }
}

@media (max-width: 1024px) {

    .p-dialog-mask .swiper-button-prev,
    .p-dialog-mask .swiper-rtl .swiper-button-next {
        position: absolute;
        width: 30px !important;
        height: 30px !important;
        border-radius: 50%;
    }

    .custom-pagination .swiper-button-next,
    .custom-pagination .swiper-button-prev {
        display: none !important;
    }

    .p-dialog-mask .swiper-button-prev:after,
    .p-dialog-mask .swiper-rtl .swiper-button-next:after {
        font-size: 13px !important;
    }

    .p-dialog-mask .swiper-rtl .swiper-button-next,
    .p-dialog-mask .swiper-button-prev {
        right: initial !important;
        left: 10px !important;
        top: 400px !important;
    }

    .p-dialog-mask .swiper-button-prev {
        left: 55px !important;
    }

    .slider-image-perview .swiper-button-prev {
        right: 10px !important;
        top: 50% !important;
    }

    .slider-image-perview .swiper-rtl .swiper-button-next {
        left: 10px !important;
        right: initial !important;
        top: 50% !important;
    }

    .p-accordion .p-accordion-content {
        padding: 10px !important;
    }

}